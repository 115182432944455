function initNav() {
    $('.btn-nav').click(function (e) {
        e.preventDefault();

        $('#navigation').slideToggle(200);
    });
}

function initSlick() {
    if ($('#carousel').length > 0) {
        $('#carousel').slick({
            dots: true,
            arrows: false,
            autoplay: true
        });
    }
}

function initProjectCarousel() {
    $('#projects_wrapper #projects').slick({
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        prevArrow: '<span class="slick-arrow slick-prev icon-angle-left"></span>',
        nextArrow: '<span class="slick-arrow slick-next icon-angle-right"></span>',
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}


function initUspsCarousel() {
    $('#usps').slick({
        arrows: false,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                }
            }
        ]
    });
}

function initMaps() {
    if ($('#maps').length > 0) {
        var latlng = new google.maps.LatLng(51.948908, 4.547251);

        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng
        });

        marker = new google.maps.Marker({
            position: latlng,
            map: map,
        });
    }
}

function initLightbox() {
    $('.lightbox').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
}

$(function () {
    initNav();
    initSlick();
    initProjectCarousel();
    initUspsCarousel();
    initMaps();
    initLightbox();
});